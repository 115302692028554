
// Imported in non-production environments only, helps with debugging
import 'zone.js/dist/zone-error';

import type { EnvironmentInterface } from '../interfaces/src/interfaces/environment.interface';

export const environment: EnvironmentInterface = {
  production: false,
  appName: 'cellar',
  firebase: {
    apiKey: "AIzaSyAg-Yltb-bL1Dp32-5W4IZQ1azejJjvYgU",
    authDomain: "mycellar-dev.firebaseapp.com",
    databaseURL: "https://mycellar-dev-default-rtdb.firebaseio.com",
    projectId: "mycellar-dev",
    storageBucket: "mycellar-dev.appspot.com",
    messagingSenderId: "103092717234",
    appId: "1:103092717234:web:e409256cbd997f73342bab",
    measurementId: "G-FVNQ2D6800"
  },
  apiUrl: 'https://us-central1-mycellar-dev.cloudfunctions.net',
  mapsApiKey: 'AIzaSyBs4kc0MSe-kxU8w2lrDfhauw2Xfq_yqKc',
  release: '1.3.0',
  envName: 'PROD',
  brandConnectUrl: 'https://dev-cellar-connect.web.app',
  searchAPIkey: 'd673dc9651f37064602675b471404a43',
  makioUrl: 'https://makio-prod.leafvip.app',
  baseBrandConnectUrl: 'https://dev-brand-connect.cellarco.io',
  baseOfficeConnectUrl: 'https://dev-office-connect.cellarco.io',
  baseTeamManagerUrl: 'https://dev-team-manager.cellarco.io',
  leafVipBrandId: 'ZQ7AtssmT3NzNnAfGfbB',
  aiReportApiUrl: 'https://aireport.codiantdev.com',
};
