import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_shared/guards/auth.guard';
import { SignOutGuard } from './_shared/guards/sign-out.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./components/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'secure',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./secure/secure.module').then((m) => m.SecureModule),
  },
  {
    path: 'sign-out',
    canActivate: [SignOutGuard],
    loadChildren: () =>
      import('./components/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '**',
    redirectTo: '/secure/team/overview',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
